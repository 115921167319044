// 
// npm dependencies library
//
(function(scope) {
	'use-strict';
	scope.__registry__ = Object.assign({}, scope.__registry__, {
		
		// list npm modules required in Haxe
		
		'react': require('react'),
		'prop-types': require('prop-types'),
		'react-addons-css-transition-group': require('react-addons-css-transition-group'),
		'react-addons-transition-group': require('react-addons-transition-group'),
		'react-dom': require('react-dom'),
		'react-router': require('react-router'),
		'redux': require('redux'),
		'react-perfect-scrollbar': require('react-perfect-scrollbar'),
		'perfect-scrollbar': require('perfect-scrollbar'),
		'sortablejs': require('sortablejs'),
		'hammerjs': require('hammerjs'),
		'mobile-detect': require('mobile-detect'),
		'react-input-mask': require('react-input-mask'),
		'react-day-picker': require('react-day-picker'),
		'viewport-units-buggyfill': require('viewport-units-buggyfill'),
		'firebase': require('firebase')
	});
	
	if (process.env.NODE_ENV !== 'production') {
		// enable hot-reload
		require('haxe-modular');
	}
	
})(typeof $hx_scope != "undefined" ? $hx_scope : $hx_scope = {});